import { axiosConfig } from './axios-config.js';

/* 狀態種類
  'leave_type' => 'required|string|in:day,night,all',
  'sleep_type' => 'required|string|in:onBedDay,onBedNight,sleepNight',
  'lying_type' => 'required|string|in:day,night,all',
*/

export const report = {
    // 取得統計圖表
    getStatistics: token => axiosConfig.get('/api/report/all', token),

    // 取得統計圖表篩選設定
    getStatisticsFilter: token => axiosConfig.get('/api/report/all/filter', token),

    // 更新統計圖表篩選設定
    /* Request Date
  "filter": {
    "leave_type": "day",
    "leave_count": 0,
    "lying_type": "day",
    "lying_count": 0,
    "sleep_type": "onBedDay",
    "sleep_count": 0,
    "restless_count": 0,
    "respiration_count": 0
    }
  */
    updateStatisticsFilter: (data, token) => axiosConfig.post('/api/report/all/filter', data, token),

    // 取得居民日報表
    /* Request Date
    "report_restless": true,
    "report_respiration": true,
    "report_bed": true,
    "notify_restless": true,
    "notify_respiration": true,
    "started_at": "2021-07-07 17:00:00",
    "ended_at": "2021-07-08 17:00:00"
  */
    getResidentDay: (id, token) => axiosConfig.get(`/api/report/resident/${id}/day-info`, token),

    // 取得居民週報表
    /* Request Date

    "type": "bed",
    "notify_leave_action": true,
    "notify_lying": true,
    "notify_leave_timeout": true,
    "notify_leave_site": true,

    "type": "respiration",
    "notify_respiration": true,

    "type": "restless",
    "notify_restless": true,

    "started_at": "2021-07-07 17:00:00",
    "ended_at": "2021-07-13 17:00:00"
  */
    getResidentWeek: (id, token) => axiosConfig.get(`/api/report/resident/${id}/week-info`, token),

    // 取得居民通知
    /* Request Date
    "started_at": "2021-07-07 17:00:00",
    "ended_at": "2021-07-13 17:00:00"
  */
    getResidentNotify: (id, token) => axiosConfig.get(`/api/report/resident/${id}/notify`, token),

    getDailyReport: (token, additionalParams = {}) => {
        return axiosConfig.get('/api/report/resident/daily-report', { headers: token.headers, params: additionalParams });
    },

    getWeeklyReport: (token, additionalParams = {}) => {
        return axiosConfig.get('/api/report/resident/weekly-report', { headers: token.headers, params: additionalParams });
    }
};
